<template>
  <div>
    <h4 class="subtitle-form">Date</h4>
    <div class="row-resize">
      <b-row>
        <!-- Start Date Text-->
        <b-col cols="6" class="text-infos shape-first-cell whitout-bg">
          <img :src="iconCalendar" alt="calendar" />
          <span class="text-activity-notes subtitle-params"> Date démarrage </span>
        </b-col>
        <b-col cols="6" class="text-infos shape-first-cell whitout-bg">
          <img :src="iconClock" alt="calendar" />
          <span class="text-activity-notes subtitle-params"> Décalage en jours </span>
        </b-col>
      </b-row>

      <!-- Ajouter le fait de reset la date si il n'a pas de date à undefined qui lui permet d'afficher une date avec le datepicker -->
      <b-row>
        <!-- Start Date Input-->
        <b-col cols="6" class="text-infos shape-first-cell whitout-bg">
          <div class="inputs-params">
            <input type="date" id="example-datepicker" ref="startDate" v-model="startDate" placeholder="yyyy-mm-dd"
             v-b-tooltip.hover :title="$t('activities.multiple.edit.tooltip.startDate')"/>
          </div>
        </b-col>

        <!-- DayPlus -->
        <b-col cols="6" class="text-infos shape-first-cell whitout-bg">
          <div class="dayplus-container"  v-b-tooltip.hover :title="$t('activities.multiple.edit.tooltip.days')">
            <b-button class="button-day" @click="minusDayplus()">-</b-button>
            <b-button class="button-day" @click="plusDayplus()">+</b-button>
            <NumericInput
              inputRef="input"
              :value="dayPlus"
              @input="i => {dayPlus = i}"
              class="input-day"
              :numberOfDigits="0"
            />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import clock from "@/assets/images/clock.svg";
import calendar from "@/assets/images/calendar.svg";
import timelapse from "@/assets/images/timelapse.svg";
import ActivityModule from "@/store/modules/activity";
import { mapGetters } from "vuex";
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import LoadFormPartMixin from "@/mixins/LoadFormPartMixin";
import { checkDatePattern, formatDate } from "@/utils/commons";
import RewriteButton from "@/components/commons/RewriteButton.vue";
import NumericInput from "@/components/commons/NumericInput.vue";

export default {
  mixins: [DetailUpdateMixin, LoadFormPartMixin],
  props: {
    useForm: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      iconClock: clock,
      iconCalendar: calendar,
      iconTimelapse: timelapse,
      startDate: "",
      errStartDate: false,
      loadComponent: false,
      dayPlus: null,
      reseted: false,
    };
  },
  created() {
    this.init();
  },
  components: {
    RewriteButton,
    NumericInput
  },
  methods: {
    init() {
      this.loadComponent = false;
      if (Object.keys(this.currentEntry).length === 0) {
        this.loadComponent = true;
        return;
      }
      // Si on modifie une activité on initialise les dates et heures
      // à partir de l'activité actuelle
      if (this.$route.name === "activity.edit") {
        if (this.currentEntry.startDate != undefined)
          this.startDate = this.currentEntry.startDate.slice(0, 10);
      }
      this.loadComponent = true;
    },
    
    resetAllStartDate() {
      this.reseted = !this.reseted;
      this.startDate = null;
    },
    minusDayplus() {
      this.dayPlus = this.dayPlus - 1;
    },
    plusDayplus() {
      this.dayPlus = this.dayPlus + 1;
    },
    /**
     * Description: Confirme le chargement des données de ce composant.
     * @return {Boolean} Component loaded.
     */
    validateForm() {
      this.isValid = true;
      return this.loadComponent;
    },
  },
  beforeCreate() {
    const store = this.$store;
    if (store && store.state) {
      if (!store.state["activity"]) {
        store.registerModule("activity", ActivityModule);
      }
    }
  },
  computed: {
    ...mapGetters({ currentActivity: "activity/currentEntry" }),
  },
  watch: {
    startDate: {
      handler: async function () {
        if (!checkDatePattern(this.startDate)) {
          this.$refs.startDate.setCustomValidity(this.$i18n.t("commons.details.datePatternError"));
        } else {
          this.$refs.startDate.setCustomValidity("");
          await this.$store.dispatch(this.storeEditAction, { startDate: this.startDate });
          if (this.dayPlus !== 0) { 
            this.dayPlus = null; 
            await this.$store.dispatch(this.storeEditAction, { dayPlus: 0 });
          }
        }
      },
    },
    dayPlus: {
      handler: async function (newVal) {
        let dayPlusInt = parseInt(newVal);
        if (isNaN(dayPlusInt)) {
          dayPlusInt = 0;
        }
        this.dayPlus = dayPlusInt;
        if (dayPlusInt !== 0) {
          await this.$store.dispatch(this.storeEditAction, { dayPlus: dayPlusInt });
          if (this.startDate) {
            this.startDate = "";
          }
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.inputs-params {
  margin: 2px 10px 3px 0px;
}

.subtitle-params {
  margin: auto 0px 2px 0px;
}

img {
  width: 1.5em;
  float: left;
  margin: auto 5px 5px auto;
}

.row-resize {
  padding-left: 16px;
}

.info-container {
  display: flex;
  align-items: center; /* Centre verticalement les éléments */
  justify-content: space-between; /* Place le texte à gauche et le bouton à droite */
}

.text-reset {
  font-size: 14px;
  padding: 5px;
  color: #222e50;
  margin: 5px;
}

.dayplus-container {
  display: flex;
  align-items: center;
  margin-right: 10%;
  gap: 5%;
}

.button-day {
  height: 100%;
  width: 30%;
  font-size: 14px;
}

.input-day{
  text-align: center;
}
</style>
